<template>
  <div class="Email_Code">
  <v-btn icon class="ml-5 mt-4 mb-n6" to="/login">
            <v-icon>mdi-chevron-left </v-icon> Login
          </v-btn>
    <div justify="center" align="center">
      <h1 class="text-center text-h4 mt-10 mx-3">Codigo de Recuperación</h1>
      <br />
      <v-img
        lazy-src="../assets/Logo_DPEI.png"
        max-height="225"
        max-width="325"
        src="../assets/Logo_DPEI.png"
      ></v-img>
    </div>
    <div class="text-center" justify="center">
      <v-dialog v-model="dialog" width="350">
        <v-card class="text-center">
          <v-card-title class="text-h4 grey justify-center">
            Invalid
          </v-card-title>
          <v-card-text class="text-h6 ">
            Usuario no existe!
          </v-card-text>
          <v-card-actions>
            <v-btn
              style=" margin-left: auto; margin-right: auto;"
              color="error"
              outlined
              @click="dialog = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-row style="height: 400px" justify="center" align="center">
      <v-col cols="md-5">
        <p class="text-center" style="margin-top: -20%;">
          Por favor ingrese usuario para recibir el código de recuperación
        </p>
        <v-form v-model="valid">
          <v-text-field
          @keydown.enter="sendCode()"
            v-model="email"
            label="E-mail"
            outlined
            :rules="reglas_email"
            required
          ></v-text-field>
          <br />
          <div class="text-center">
            <v-btn
              :disabled="!valid"
              color="success"
              class="mr-4"
              v-on:click="sendCode"
              >Enviar Codigo
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
export default {
  name: "Login",
  data: () => ({
    valid: true,
    dialog: false,
    email: "",
    reglas_email: [(v) => !!v || "Email is required"],
    url: process.env.VUE_APP_ROOT_URL + "user/request-code",
  }),
  methods: {
    sendCode() {
      let vueInstance = this;
       this.email = this.email.toLowerCase();
      this.email = this.email.replace(/\s+/g, "");
      axios({
        method: "POST",
        url: this.url,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: this.email,
        },
      })
        .then(function(response) {
          window.location.replace("forgot");
        })
        .catch(function(error) {
          console.log(error);
          vueInstance.password = "";
          vueInstance.email = "";
          vueInstance.dialog = true;
        });
    },
  },
};
</script>
